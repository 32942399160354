import React from 'react';
import { navigate } from 'gatsby';
import Layout from 'src/components/layouts/default';
import StructuredText from 'src/components/base/structuredText';
import * as classes from './complete.module.scss';
import { useHasMounted } from '../util';

const CompletePage = ({ location }) => {
  // You can't directly load this page -- it's only for after submitting a form.
  const hasMounted = useHasMounted();

  if (!location?.state?.pageTitle) {
    if (hasMounted) {
      // don't try to redirect during SSR
      navigate('/', { replace: true });
    }
    return null;
  }

  return (
    <Layout pageTitle={location.state.pageTitle}>
      <div className={classes.body} data-calltrk-noswap>
        <StructuredText data={location.state.body} tokenValues={location.state.tokenValues} />
      </div>
    </Layout>
  );
};

export default CompletePage;
